import React, { useContext, useEffect, useState, useRef } from 'react';

import {
    IonPage,
    IonCol,
    IonContent,
    IonGrid,
    IonRow,
    IonButton,
    useIonToast,
    useIonLoading,
    IonAlert,
    IonFooter,
    IonToolbar
} from '@ionic/react';

import { useHistory } from 'react-router-dom'

import { DataContext } from '../../Providers/DataProvider';

import './ViewOfferSuccess.scoped.scss';

import tableone from '../../Assets/tableOutline-white.png'

// https://stackoverflow.com/questions/59426050/how-to-stop-re-render-of-component-on-route-changeusually-on-going-back-to-hist
const ViewOfferSuccess = () => {
    const history = useHistory();

    const {
        handleManageSubscription
    } = useContext(DataContext)

    const [showModal, setShowModal] = useState(false)
    const [phone, setPhone] = useState(false)

    const [presentAlert] = useIonToast();
    const [present, dismiss] = useIonLoading();

    function validPhone(phone) {
        if (phone?.length) {
            return phone.match(/\d/g).length === 10 && !['0', '1'].includes(Array.from(phone)[0])
        } else {
            return false;
        }
    }

    async function handlePhone() {
        if (validPhone(phone)) {
            await present('Sending text...')

            try {
                await handleManageSubscription(phone);
            } catch (error) {
                console.log('handlePhone ERROR: ', error)

                presentAlert({
                    message: 'Error occured!',
                    duration: 1000,
                    position: 'middle',
                    // cssClass: 'custom-alert',
                });

                await dismiss();

                return;
            }

            setTimeout(async () => {
                await dismiss();

                presentAlert({
                    message: 'Text sent!',
                    duration: 1000,
                    position: 'middle',
                    // cssClass: 'custom-alert',
                });
            }, 10000);
        } else {
            presentAlert({
                message: 'Please provide valid phone number!',
                duration: 1000,
                position: 'middle',
                // cssClass: 'custom-alert',
            });
        }
    }


	async function toHome() {
		history.push(`/`);
	}

    useEffect(() => {
        if (phone && !showModal) {
            handlePhone()
        }
    }, [phone, showModal])



    return (
        <IonPage fullscreen>
            <IonAlert
                isOpen={showModal}
                onDidDismiss={() => (setShowModal(false))}
                header="Enter your phone number"
                subHeader="We'll text you a secure link to complete your subscription!"
                buttons={[
                    {
                        text: 'Send Text',
                        role: 'confirm',
                        handler: (e) => {
                            setPhone(e?.phone)
                        },
                    },
                ]}
                inputs={[
                    {
                        name: 'phone',
                        placeholder: 'Phone',
                        value: phone,
                        type: 'number',

                    },
                ]}
            >


            </IonAlert>

            <IonContent fullscreen={true} style={{ textAlign: 'center',}}>
                <IonGrid style={{ maxWidth: '100rem',  }}>
                    {/* <IonRow style={{ marginTop: '1rem' }}>
                        <IonCol style={{ textAlign: 'center' }}>
                            <img src={tableone} style={{ maxHeight: '6rem' }} />
                        </IonCol>
                    </IonRow> */}
                    <IonRow className='center-row' style={{marginTop:'1rem'}}>
                    <IonCol size={0.5} />
                        <IonCol style={{ textAlign: 'left' }}>
                            <span
                                style={{
                                    fontFamily: "'Reem Kufi', sans-serif",
                                    letterSpacing: '0.5rem',
                                    fontSize: '18px',
                                    // width: '100%',
                                }}
                            >
                                THANK <span style={{
                                    fontFamily: "'Reem Kufi', sans-serif",

                                    color: "#F2C498"
                                }}>YOU</span><br />
                               FOR SUPPORTING TABLEONE.
                            </span>
                        </IonCol>
                        <IonCol size={2}>
                        <img src={tableone} style={{ maxHeight: '6rem' }} className='spin animated'/>
                        </IonCol>
                        <IonCol size={0.5} />
                    </IonRow>
                    <IonRow style={{ marginTop: '2rem' }}>
                        <IonCol size={0.5} />
                        <IonCol style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: '14px' }}>
                                Without you, we wouldn't be able to continue building TableOne. We've got <span style={{ color: "#F2C498" }}>big plans</span>, and hope you enjoy being a member.<br /><br />
                            </span>
                        </IonCol>
                        <IonCol size={0.5} />
                    </IonRow>
                    <IonRow style={{ marginTop: '2rem', marginBottom: '10rem' }} className='center-row'>
                    <IonCol size={0.5} />
                        <IonCol style={{textAlign: 'left'}}>
                            <span style={{ fontSize: '14px' }}>
                                Frank & Tarek
                            </span>
                        </IonCol>
                        <IonCol size={0.5} />
                    </IonRow>

                </IonGrid>

            </IonContent>

            {/* <IonFooter className="ion-no-border" style={{ position: 'fixed', bottom: '2vh', textAlign: 'center' }}>
                    <IonToolbar style={{ textAlign: 'right', '--background': 'transparent' }}>
                        <IonButton
                            style={{
                                '--background': '#FFFFFF',
                                '--background-activated': '#FFFFFF',
                                '--border-radius': '32px',
                                marginRight: '2vh'
                            }}
                            onClick={() => (setShowModal(true))}
                        >
                            <span style={{ fontSize: '12px', color: '#000000' }}>
                                Claim your offer
                            </span>
                        </IonButton>
                    </IonToolbar>
                </IonFooter> */}
        </IonPage>
    )

}

export { ViewOfferSuccess };
