import React, { useContext, useEffect, useState, useRef } from 'react';

import {
	IonPage,
	IonCol,
	IonContent,
	IonGrid,
	IonRow,
	IonButton,
	IonModal,
	IonFooter,
	IonToolbar,
	IonButtons,
	IonHeader,
	useIonAlert,
	useIonLoading
} from '@ionic/react';

import newyorker from '../../Assets/news-logos/newyorker-stack.png'
import gothamist from '../../Assets/news-logos/gothamist.png'
import forbes from '../../Assets//news-logos/forbes.png'
import linkedin from '../../Assets/news-logos/linkedin.png'

import { FaApple } from "react-icons/fa";

import { DataContext } from '../../Providers/DataProvider';
import { deviceType } from 'react-device-detect';

import { IoIosArrowForward } from "react-icons/io";


import tableone from '../../Assets/tableone-gold.png'

import './ViewRoadmap.scoped.scss';

// https://stackoverflow.com/questions/59426050/how-to-stop-re-render-of-component-on-route-changeusually-on-going-back-to-hist
const ViewRoadmap = () => {
	const queryParameters = new URLSearchParams(window.location.search)

	const [loadingQueryParams, setLoadingQueryParams] = useState(true);
	const [referrer, setReferrer] = useState(false);

	const {
		handleReferral,
		setShowSubModal
	} = useContext(DataContext)

	const [localEmail, setLocalEmail] = useState('')

	// const [presentAlert, dismiss] = useIonToast();
	const [presentAlert] = useIonAlert();
	const [present, dismiss] = useIonLoading();

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	async function handleSubmit() {
		const queryParameters = new URLSearchParams(window.location.search)


		const referrer = queryParameters.get('referrer')
		console.log(localEmail, referrer.length)

		if (!validateEmail(localEmail) || referrer.length !== 36) {
			presentAlert({
				message: 'Valid email & referrer Required',
				duration: 1500,
				position: 'middle',
				cssClass: 'custom-alert',
				buttons: ['OK']
			});
			return;
		} else {
			await present('Saving referral')

			await handleReferral(
				referrer,
				localEmail
			)

			await dismiss()

			window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_self');
		}

	}

	useEffect(() => {
		async function handle() {
			const tmpReferrer = queryParameters.get('referrer');

			if (tmpReferrer?.length == 36) {
				setReferrer(tmpReferrer);
			}

			setLoadingQueryParams(false);
		}

		handle();
	}, [queryParameters])

	function toArticle(url) {
		window.open(url, '_blank');
	}

	async function toDownload() {
		// const os = getMobileOperatingSystem()

		window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_blank');

		// if (os === "iOS") {
		// 	window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_blank');
		// } 
		// else if (os === "Android") {
		// 	window.open('https://play.google.com/store/apps/details?id=com.fyi.hooli.app', '_blank');
		// } 
		// else {
		// 	window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_blank');
		// }
	};

	return (
		<IonPage fullscreen>
			<IonHeader style={{ '--background': 'transparent' }}>
				<IonGrid style={{ '--background': 'transparent' }}>
					<IonRow>
						{/* <IonCol size={1}>
						<img src={tableOutline} className='spin animated' style={{maxHeight: '5rem'}}/>
						</IonCol> */}
						<IonCol style={{ textAlign: 'left', marginLeft: '1rem', marginTop: '1rem' }}>
							<img src={tableone} style={{ height: '4rem' }} />
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonHeader>


			<IonFooter style={{ '--background': 'transparent' }} class="ion-no-border">
				<IonGrid style={{maxWidth: '45rem'}}>
					<IonRow style={{ 'marginTop': '0rem' }}>
						<IonCol size={12} style={{ textAlign: 'center' }}>
							<p style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.2rem',
								color: '#F6F1EF',
							}}>
								TableOne Solutions Inc. © 2024
							</p>
						</IonCol>
						{/* <IonCol size={1} style={{ textAlign: 'center' }}>
							<p style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.2rem',
								color: '#F6F1EF',
							}}>
								|
							</p>
						</IonCol>
						<IonCol size={5} style={{ textAlign: 'left' }}>
							<p style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.2rem',
								color: '#F6F1EF',
								cursor: "pointer"
							}}
							onClick={()=>(setShowSubModal(true))}
							>
								Manage Subscription
							</p>
						</IonCol> */}
					</IonRow>
				</IonGrid>
			</IonFooter>

			{/* <ModalSignup show={showSignup} setShow={setShowSignup} closeable={true} /> */}
			<IonContent fullscreen={true} style={{ textAlign: 'center', }}>

				<IonGrid
					style={{
						// background: '',
						padding: '0rem',
						// width: '100vw',
						maxWidth: '100rem'
					}}
				>
					<IonRow style={{ paddingTop: deviceType === "mobile" ? '0rem' : '5vh' }}>
						<IonCol size={12}>

							{(!loadingQueryParams && !referrer) && <>
								<IonRow style={{ marginTop: deviceType === "mobile" ? '6rem' : '5rem' }}>
									<IonCol>
										<span style={{
											fontFamily: "'Nunito Sans', sans-serif",
											fontSize: deviceType === "mobile" ? '2.2rem' : '6rem',
											color: '#F6F1EF'
										}}>
											a <span style={{ color: "#F2C498" }}>next-gen</span> reservation platform<br />built for <span style={{ color: "#F2C498" }}>diners</span>.
										</span>
									</IonCol>
								</IonRow>

								<IonRow style={{ marginTop: '2rem', marginBottom: '5rem' }}>
									<IonCol>
										<span style={{
											fontFamily: "'Nunito Sans', sans-serif",
											fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
											color: '#F6F1EF'
										}}>
											with only the best restaurants.
										</span>
									</IonCol>
								</IonRow>

								<IonRow style={{ marginBottom: '5vh' }}>
									<IonCol>
										{deviceType !== "mobile" &&
											<IonButton
												onClick={() => (toDownload())}
												shape="round"
												style={{
													fontSize: deviceType === "mobile" ? '1.4rem' : '2rem',
													fontWeight: 600,
													height: '6.4rem',
													// width: '20rem',
													'--background': '#F6F1EF',
													'--background-activated': '#F6F1EF',
													'--background-hover': '#F6F1EF',
													'--color': '#000000'

												}}
											>
												<IonGrid>
													<IonRow className='center-row'>
														{/* <IonCol size={2}/> */}
														<IonCol style={{ textAlign: 'right' }}>
															<FaApple size={18} />
														</IonCol>
														<IonCol style={{ textAlign: 'left' }}>
															<span>Download Now</span>
														</IonCol>
														{/* <IonCol size={2}/> */}
													</IonRow>
												</IonGrid>

											</IonButton>
										}
										{deviceType === "mobile" &&
											<IonButton
												onClick={() => (toDownload())}
												// shape="round"
												style={{
													fontSize: '1.2rem', fontWeight: 700,
													height: '5rem',
													width: '14rem',
													'--padding-start': 0,
													'--padding-end': 0,
													'--border-radius': '36px',
													'--background': '#F6F1EF',
													'--background-activatsed': '#F6F1EF',
													'--background-hover': '#F6F1EF',
													'--color': '#000000',
													textAlign: 'center',
													fontWeight: 600,

												}}
											>
												<FaApple size={15} />
												<span style={{ marginTop: '2px', marginLeft: '4px' }}>Download Now</span>
											</IonButton>
										}
									</IonCol>
								</IonRow>
							</>
							}
							{(!loadingQueryParams && referrer) && <>
								<IonRow style={{ marginTop: deviceType === "mobile" ? '6rem' : '5rem' }}>
									<IonCol style={{ textAlign: 'center' }}>
										{deviceType === "mobile" &&
											<span style={{
												color: '#f6f1ef',
												fontSize: '2.4rem',
												fontFamily: "'Nunito Sans', sans-serif",
											}}>
												Enter your <span style={{ color: '#F2C498' }}>email address.</span>
											</span>
										}

										{deviceType !== "mobile" &&
											<span style={{
												color: '#f6f1ef',
												fontSize: '2.4rem',
												fontFamily: "'Nunito Sans', sans-serif",
												marginBottom: '5rem'
											}}>
												Visit on a <span style={{ color: '#F2C498' }}>phone.</span>
											</span>
										}
									</IonCol>
								</IonRow>

								{deviceType === "mobile" &&
									<IonRow style={{ marginTop: '1rem', marginBottom: deviceType === "mobile" ? '6rem' : '5rem' }}>
										<IonCol size={1} />
										<IonCol size={8} style={{ textAlign: 'center', }}>
											<form>
												<input
													label="Fixed label"
													labelPlacement="fixed"
													type="email"
													value={localEmail}
													placeholder='frank@tableone.app'
													autocomplete="email"
													// maxlength={15}
													style={{
														fontSize: 'auto',
														fontFamily: "Nunito Sans', sans-serif",
														'text-align': 'center',
														marginLeft: '4px',
														'placeholder-color': '#c0c0c0',
														'background': '#f7f6f6', // #e4d4ce
														'border-color': '#110E2D',
														'border-style': 'solid',
														'border-width': '2px',
														'border-radius': '32px',
														'height': '4.7rem',
														'width': '100%'
													}}

													onChange={(e) => (setLocalEmail(e.target.value))}
												// onBlur={() => (handleSubmit())}
												// disabled={currentUser?.uid}
												// onIonBlur={() => (handleSubmit())}
												// autofocus={true}
												// ref={props.focusInput}
												/>
											</form>
										</IonCol>
										<IonCol size={2}>
											<IonButton style={{
												'height': '4rem',
											}}
											onClick={handleSubmit}
											>
												<IoIosArrowForward size={20} />

											</IonButton>
										</IonCol>
										<IonCol size={1} />

									</IonRow>
								}
							</>}

							<IonRow>
								<IonCol>
									<hr style={{
										marginTop: deviceType === "mobile" ? '1rem' : '2rem',
										marginBottom: '1rem',
										color: '#7a7a7a62',
										backgroundColor: '#7a7a7a62',
										width: '85%'
									}} />
								</IonCol>
							</IonRow>


							<IonRow style={{ 'marginTop': deviceType === "mobile" ? '2.5rem' : '5rem' }}>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.6rem',
										color: '#F2C498'
									}}>
										Featured in
									</span>
								</IonCol>
							</IonRow>

							{/* {deviceType !== "mobile" && */}
							<IonRow style={{ 'marginTop': deviceType === "mobile" ? '3rem' : '4rem' }} >
								<IonCol sizeXs={0} sizeSm={0} sizeMd={2} />
								<IonCol>
									<img src={forbes} className='center-image' style={{ cursor: 'pointer', maxWidth: deviceType === 'mobile' ? '7rem ' : '11rem' }} onClick={() => (toArticle('https://www.forbes.com/sites/lauriewerner/2024/04/27/mothers-day-gift-guide-2024-top-new-york-restaurant-gift-certificates'))} />
								</IonCol>
								<IonCol>
									<img src={newyorker} className='center-image' style={{ cursor: 'pointer', maxWidth: deviceType === 'mobile' ? '7rem ' : '11rem' }} onClick={() => (toArticle('https://www.newyorker.com/news/our-local-correspondents/why-you-cant-get-a-restaurant-reservation'))} />
								</IonCol>
								<IonCol>
									<img src={gothamist} className='center-image' style={{ cursor: 'pointer', maxWidth: deviceType === 'mobile' ? '7rem ' : '11rem' }} onClick={() => (toArticle('https://gothamist.com/arts-entertainment/dinner-reservations-at-tatiana-are-impossible-to-get-so-i-spent-a-month-trying'))} />
								</IonCol>
								<IonCol size={deviceType === "mobile" ? 1.5 : 2}>
									<img src={linkedin} className='center-image' style={{ maxHeight: deviceType === 'mobile' ? '3rem ' : '5rem', textAlign: 'center' }} />
								</IonCol>
								<IonCol sizeXs={0} sizeSm={0} sizeMd={2} />
							</IonRow>
							{/* } */}
							{/* 
							{deviceType === "mobile" &&
								<>
									<IonRow style={{ 'marginTop': '3rem' }} >
										<IonCol sizeXs={0} sizeSm={0} sizeMd={3} />
										<IonCol>
											<img src={forbes} className='center-image' style={{ cursor: 'pointer', maxWidth: '7rem' }} onClick={() => (toArticle('https://www.forbes.com/sites/lauriewerner/2024/04/27/mothers-day-gift-guide-2024-top-new-york-restaurant-gift-certificates'))} />
										</IonCol>
										<IonCol sizeXs={0} sizeSm={0} sizeMd={3} />
									</IonRow>
									<IonRow style={{ 'marginTop': '3rem' }} >
										<IonCol sizeXs={0} sizeSm={0} sizeMd={3} />
										<IonCol>
											<img src={newyorker} className='center-image' style={{ cursor: 'pointer', maxWidth: '7rem' }} onClick={() => (toArticle('https://www.newyorker.com/news/our-local-correspondents/why-you-cant-get-a-restaurant-reservation'))} />
										</IonCol>
										<IonCol sizeXs={0} sizeSm={0} sizeMd={3} />
									</IonRow>
									<IonRow style={{ 'marginTop': deviceType === "mobile" ? '6rem' : '4rem' }} >
										<IonCol sizeXs={0} sizeSm={0} sizeMd={3} />
										<IonCol>
											<img src={gothamist} className='center-image' style={{ cursor: 'pointer', maxWidth: '7rem' }} onClick={() => (toArticle('https://gothamist.com/arts-entertainment/dinner-reservations-at-tatiana-are-impossible-to-get-so-i-spent-a-month-trying'))} />
										</IonCol>
										<IonCol sizeXs={0} sizeSm={0} sizeMd={3} />
									</IonRow>
									<IonRow style={{ 'marginTop': deviceType === "mobile" ? '6rem' : '4rem' }} >
										<IonCol sizeXs={0} sizeSm={0} sizeMd={3} />
										<IonCol>
											<img src={linkedin} className='center-image' style={{ cursor: 'pointer', maxHeight: '3rem', textAlign: 'center' }} onClick={() => (toArticle('https://gothamist.com/arts-entertainment/dinner-reservations-at-tatiana-are-impossible-to-get-so-i-spent-a-month-trying'))} />
										</IonCol>
										<IonCol sizeXs={0} sizeSm={0} sizeMd={3} />
									</IonRow>
								</>
							} */}
						</IonCol>
					</IonRow>

					{/* <IonRow style={{ marginBottom: '4rem' }}>
						<IonCol sizeXs={1} sizeSm={1} sizeMd={3} />
						<IonCol>
							<p style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: deviceType === "mobile" ? '1.2rem' : '1.8rem',
								fontWeight: '300',
								color: '#7a7a7a',
								wordSpacing: '0.5rem',
								letterSpacing: '0.2rem'
							}}>
								REAL-TIME TABLE INVENTORY, A BRAND NEW SEARCH FUNCTION, AND REVAMPED RESTAURANT INSIGHTS.
							</p>
						</IonCol>
						<IonCol sizeXs={1} sizeSm={1} sizeMd={3} />
					</IonRow> */}

					{/* <IonRow>
						<IonCol sizeXs={1} sizeSm={1} sizeMd={3} />
						<IonCol>
							<img src={preview1} />
						</IonCol>
						<IonCol sizeXs={1} sizeSm={1} sizeMd={3} />
					</IonRow> */}






				</IonGrid>
			</IonContent >
		</IonPage >
	)

}

export { ViewRoadmap };
