import React, { useState, useEffect } from 'react';

import {
	realm,
	doLogin,
	doLoginAnon,
	doInsertReferral,
	doManageSubscription,
} from '../Helpers/Mongo'

import {
	IonAlert,
    useIonToast,
    useIonLoading,
} from '@ionic/react';

const DataContext = React.createContext();

const DataProvider = ({ children }) => {
	const [user, setUser] = useState("");
	const [shareUser, setShareUser] = useState(false);

    const [showSubModal, setShowSubModal] = useState(false)

    const [presentAlert] = useIonToast();
    const [present, dismiss] = useIonLoading();

	async function login(user) {
		await doLogin(user)
		
	}

	async function handleReferral(referrer, email) {
		await doInsertReferral(referrer, email)
	}

	async function handleReferral(referrer, email) {
		await doInsertReferral(referrer, email)
	}

	async function handleManageSubscription(phone) {
		await doManageSubscription(phone)
	}


    function validPhone(phone) {
        if (typeof(phone) === "string") {
            return phone.match(/\d/g).length === 10 && !['0', '1'].includes(Array.from(phone)[0])
        } else {
            return false;
        }
    }

	async function handlePhone(phone) {
		let tmpPhone = phone.replace(/[^0-9]+/g, '');  

		const tmpPhoneArray = Array.from(tmpPhone);
		
		if (tmpPhoneArray[0] === '1') {
			tmpPhoneArray.shift()
		}

		tmpPhone = tmpPhoneArray.join('');


		if (validPhone(tmpPhone)) {
			console.log(tmpPhone)

			await present('Sending text...')

            try {
                await handleManageSubscription(tmpPhone);
            } catch (error) {
                console.log('handlePhone ERROR: ', error)

                presentAlert({
                    message: 'Error occured!',
                    duration: 1000,
                    position: 'middle',
                    // cssClass: 'custom-alert',
                });

                await dismiss();

                return;
            }

            setTimeout(async () => {
                await dismiss();

                presentAlert({
                    message: 'Text sent!',
                    duration: 1000,
                    position: 'middle',
                    // cssClass: 'custom-alert',
                });
            }, 10000);
		} else {
			presentAlert({
                message: 'Please provide valid phone number!',
                duration: 1000,
                position: 'middle',
                // cssClass: 'custom-alert',
            });
		}
	}

	useEffect(() => {
		// if (queryParameters.get('user')) {
		// 	setShareUser(queryParameters.get('user'))
		// }
		async function init() {
			await doLoginAnon();
			// await handleLeaderboard()
		}

		init()
	}, [])

	// useEffect(() => {
    //     if (phone && !showSubModal) {
    //         handlePhone()
    //     }
    // }, [phone, showSubModal])

	// useEffect(() => {
	// 	if (shareUser) {
	// 		findBracket(shareUser)
	// 	}
	// }, [shareUser])

	return (
		<DataContext.Provider value={{ 
			login,
			user, 
			shareUser, 
			setShareUser, 
			handleReferral,
			handleManageSubscription,
			setShowSubModal,
		}}>
            <IonAlert
                isOpen={showSubModal}
                onDidDismiss={() => (setShowSubModal(false))}
                header="Enter your phone number"
                subHeader="We'll text you a secure link to manage your subscription!"
                buttons={[
                    {
                        text: 'Send Text',
                        role: 'confirm',
                        handler: (e) => {
                            handlePhone(e?.phone)
                        },
                    },
                ]}
                inputs={[
                    {
                        name: 'phone',
                        placeholder: 'Phone',
                        // value: phone,
                        type: 'tel',

                    },
                ]}
            />

			{children}
		</DataContext.Provider>
	);

}

export {
	DataContext,
	DataProvider
};